import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import Layout from "../components/layout";
import HeroHome from "../components/hero-home";
import HeroImage from "../components/hero-image";
import Hero from "../components/hero";
import NextPage from "../components/next-page";
import ZFlow from "../components/z-flow";
import VisionCarousel from "../components/vision-carousel";
import Portfolio from "../components/portfolio";
import TeamGridBlock from "../components/team-grid/team_grid";
import TeamSorterBlock from "../components/team-sorter/team_sorter";
import DividerBlock from "../components/divider/divider";
import JobsListing from "../components/jobs-listing";

const LandingPageBuilderPage = ({ location, pageContext, data }) => {
	const entry = data.cms.entry;
	const seo = data.cms.SeoMeta;
	const nav = data.cms.nav;
	const footer_nav = data.cms.footer_nav;
	const global = data.cms.globalSet;
	const portfolio_companies = data.cms.portfolio_companies;
	const taxonomies = data.cms.taxonomies
	const sectors = data.cms.sectors
	const region = data.cms.region
	const status = data.cms.status
	const search = data.cms.search
	const team = data.cms.team
	const team_taxonomies = data.cms.team_taxonomies
	const team_members = data.cms.team

	return (
		<Layout nav={nav} footer_nav={footer_nav} global={global} seo={seo} search={search} team={team} portfolio_companies={portfolio_companies} location={location} theme="light" mobileTheme="light">
			<Seo
				title={seo.og_title}
				description={seo.og_description}
				meta={[
					{
						property: "og:image",
						content: "/images/og-image-1.jpg",
					},
				]}
			/>

			<Helmet>
				<script type="application/ld+json">
					{`
                    {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "SoftBank Latin America Fund",
                        "url": "https://latinamericafund.com",
                        "logo": "https://latinamericafund.com/images/og-image-1.jpg",
                        "alternateName": "The SoftBank Latin America Fund"
                    }
                    `}
				</script>
			</Helmet>
			{entry.modules.map((module, index) => {
				//console.log("each: ", module.type);
				switch (module.type) {
					case "hero":
						return (
							<HeroHome
								key={index}
								heading={module.heading}
								headingMeta={module.headingmeta}
								imageAlt={module.imagealt}
								imageUrl={module.image?.publicURL}
								imageFile={module.image?.imageFile}
								video={module.video}
								videoAssets={module.video_assets}
								theme="light" mobileTheme="light"
							/>
						);
					case "hero_image":
						return (
							<HeroImage
								key={index}
								heading={module.heading}
								headingMeta={module.headingmeta}
								imageAlt={module.imagealt}
								imageUrl={module.image.publicURL}
								imageFile={module.image.imageFile}
								imageMobileFile={module.image_mobile?.imageFile}
								theme="light" mobileTheme="light"
							/>
						);
					case "standard_hero":
						return (
							<Hero
								key={index}
								heading={module.heading}
								blurb={module.blurb}
								blurb_mobile={module.blurb_mobile}
								reduced={module.reduced}
								showScrollToContent={module.showScrollToContent}
								style={module.style}
								theme="light" mobileTheme="light"
							/>
						);
					case "next_page":
						return (
							<NextPage
								key={index}
								heading={module.heading}
								link={module.link}
								link_label={module.link_label}
							/>
						);
					case "z-flow":
						return (
							<ZFlow 
								key={index} 
								sections={module.zflow_items}
								padding_top={module.padding_top}
							/>
						);
					case "vision_carousel":
						return <VisionCarousel key={index} vc_items={module.vc_items} />;
					case "portfolio":
						return <Portfolio key={index} categories={module.categories} legal_text={module.legal_text} portfolio_companies={portfolio_companies} taxonomies={taxonomies} sectors={sectors} region={region} status={status} seo={seo} />;
					case "team_grid":
						return (
							<TeamGridBlock key={index} team={module.team} heading={module.heading} />
						);
					case "team_sorter":
						return (
							<TeamSorterBlock
								key={index}
								heading={module.heading}
								excluded_team_members={module.excluded_team_members}
								seo={seo}
								team_members={team_members}
								team_taxonomies={team_taxonomies}
								sectors={sectors}
								region={region}
							/>
						);
					case "divider":
						return <DividerBlock key={index} />;
					case "careers":
						return (
							<JobsListing
								key={index}
								heading={module.heading}
								contact_label={module.contact_label}
								initial_location={module.initial_location}
								path={""}
								seo={seo}
							/>
						);
					default:
						return null;
				}
			})}
		</Layout>
	);
};

export default LandingPageBuilderPage;

export const query = graphql`
	fragment CMSAsset on cms_Asset_Assets {
		... on cms_Asset_Assets {
			id
			alt
			focus_css
			imageFile {
				publicURL
				extension
				childImageSharp {
					gatsbyImageData(width: 4000, formats: [AUTO, WEBP, AVIF])
				}
				absolutePath
				atime
				base
			}
			path
		}
		url
		path
	}
	query LandingPageBuilderPageQuery($pageId: String, $siteHandle: String) {
		cms {
			nav(handle: "main_nav") {
				tree(site: $siteHandle) {
					page {
						title
						url
						... on cms_NavPage_MainNav {
							external
						}
					}
					children {
						page {
							title
							url
						}
					}
				}
			}
			footer_nav: nav(handle: "footer_nav") {
				tree(site: $siteHandle) {
					page {
						title
						url
					}
					children {
						page {
							title
							url
						}
					}
				}
			}
			globalSet(handle: "footer", site: $siteHandle) {
				... on cms_GlobalSet_Footer {
					copyright
					links {
					... on cms_Set_Links_Links {
						link_link
						link_label
						link_external
						link_btn_type {
						label
						value
						}
					}
					}
				}
			}
			SeoMeta(page_id: $pageId) {
				og_title
				og_description
				description
				og_url
				rel_canonical
				translations {
					exists
					id
					is_current
					site
					uri
				}
			}
			entry(id: $pageId) {
				id
				title
				slug
				... on cms_Entry_Pages_PageModular {
					id
					url
					uri
					title
					slug
					permalink
					modules {
						... on cms_Set_Modules_Hero {
							type
							heading
							headingmeta
							imagealt
							image {
								...CMSAsset
							}
							video
							video_assets {
								video {
									...CMSAsset
								}
								visibility {
									value
								}
							}
						}
						... on cms_Set_Modules_HeroImage {
							heading
							headingmeta
							type
							imagealt
							image {
								...CMSAsset
							}
							image_mobile {
								...CMSAsset
							}
						}
						... on cms_Set_Modules_StandardHero {
							heading
							blurb
							blurb_mobile
							reduced
							type
							showScrollToContent
							style {
								label
								value
							}
						}
						... on cms_Set_Modules_NextPage {
							heading
							link
							link_label
							type
						}
						... on cms_Set_Modules_ZFlow {
							type
							padding_top
							zflow_items {
								... on cms_Set_ZflowItems_Item {
									heading
									body_copy
									type
									eyebrow
									media_caption
									item_style {
										value
										label
									}
									item_alignment {
										value
										label
									}
									image_ratio {
										label
										value
									}
									media {
										...CMSAsset
									}
								}
							}
						}
						... on cms_Set_Modules_VisionCarousel {
							type
							vc_items {
								... on cms_Set_VcItems_Item {
									item_title
									quote
									link
									link_label
									type
								}
							}
						}
						... on cms_Set_Modules_Portfolio {
							type
							categories {
								title
								handle
							}
							legal_text
						}
						... on cms_Set_Modules_TeamGrid {
							heading
							team {
								... on cms_Set_Team_Member {
									type
									prevent_overlay_opening
									team_member {
										... on cms_Entry_Team_Team {
											title
											slug
											description
											long_description
											preview_image {
												...CMSAsset
											}
										}
									}
								}
							}
							type
						}
						... on cms_Set_Modules_TeamSorter {
							heading
							excluded_team_members {
								slug
								id
							}
							type
						}
						... on cms_Set_Modules_Divider {
							type
						}
						... on cms_Set_Modules_Careers {
							headline
							contact_label
							initial_location {
							  title
							}
							type
						  }
					}
				}
			}
			portfolio_companies: entries(collection: "companies", sort: "title", filter: {site: $siteHandle}) {
				to
				data {
					id
					slug
					... on cms_Entry_Companies_Companies {
						__typename
						id
						title
						slug
						founder_label
						founders
						company_url
						description
						status
						status_override
						company_status {
							id
							slug
							title
						}
						site {
							short_locale
							locale
							handle
							name
			
						}
						team {
							hide_on_portfolio_page
							team_member {
								id
								title
							}
						}
						region {
							... on cms_Term_Region_Region {
								id
								title
								slug
							}
						}
						sectors {
							... on cms_Term_Sectors_Sectors {
								id
								title
								slug
							}
						}
						logo {
							... on cms_Asset_Assets {
							id
							alt
							imageFile {
								publicURL
								extension
								childImageSharp {
								gatsbyImageData(width: 500, formats: [AUTO, WEBP, AVIF])
								}
								absolutePath
								atime
								base
							}
							path
							}
							url
							path
						}
					}
				}
				total
				from
				has_more_pages
			}
			taxonomies: taxonomies {
				title
				handle
			},
			team_taxonomies: terms(taxonomy: "team_tax", filter: {site: $siteHandle}) {
				data {
				  id
				  ... on cms_Term_TeamTax_TeamTax {
					id
					title
					display_title
					main_category
					order
					parent {
					  ... on cms_Term_TeamTax_TeamTax {
						id
						title
						order
					  }
					}
				  }
				}
			},
			sectors: terms(taxonomy: "sectors", filter: {site: $siteHandle}) {
				data {
					title
					slug
				}
			},
			region: terms(taxonomy: "region", filter: {site: $siteHandle}) {
				data {
					title
					slug
				}
			}
			status: terms(taxonomy: "status", filter: {site: $siteHandle}) {
				data {
					title
					slug
				}
			}
			search: globalSet(handle: "search", site: $siteHandle) {
				... on cms_GlobalSet_Search {
						handle
						results_text
						no_results_subtext
						no_results_text
						search_input_field
						companies_headline
						investors_headline
						suggested_search_terms {
						name
					}
					site {
						handle
						name
					}
				}
			},
			team: entries(collection: "team", limit: 3000, filter: {site: $siteHandle}) {
				to
				data {
					id
					... on cms_Entry_Team_Team {
						__typename
						id
						title
						description
						long_description
						slug
						published
						site {
							short_locale
							locale
							handle
							name
			
						}
						preview_image {
							... on cms_Asset_Assets {
								id
								alt
								path
								width
								height
								imageFile {
									publicURL
									extension
									childImageSharp {
									gatsbyImageData(width: 90, height: 90, formats: [AUTO, WEBP, AVIF])
									}
									absolutePath
									atime
									base
								}
								path
							}
						}
						sectors {
							... on cms_Term_Sectors_Sectors {
								title
								slug
								id
							}
						}
						region {
							id
							... on cms_Term_Region_Region {
								title
								slug
								id
							}
						}
						team_category {
							... on cms_Term_TeamTax_TeamTax {
							  taxonomy {
								title
							  }
							  slug
							  title
							  id
							  main_category
							  parent {
								slug
								title
								id
								taxonomy {
								  title
								}
							  }
							}
						}
					}
				}
				total
				from
				has_more_pages
			}

		}
	}
`;
